<template>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{new Date().getFullYear()}} © Virtual Bodyguard BV
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                  Phishing Prevention & Protection Platform - <a href="https://www.bodyguard.io">Bodyguard.io</a>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
